/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { CircleButton } from '../Buttons/Buttons'
import { baseFont } from '../../assets/styles/styles'
import { flexHorizontallyCentered } from '../../assets/styles/layout'
import { colors } from '../../assets/styles/colors'
import { resetNativeInput } from '../../assets/styles/input'
import { iconHex } from '../../assets/styles/icons'
import { useCallback } from 'react'

const inputStyle = (isReadOnly = true) => css`
  ${resetNativeInput};
  ${isReadOnly
    ? `
    pointer-events: none;
  `
    : `
    outline: 0;
    padding: 0.25rem;
    cursor: pointer;
  `}
  appearance: textfield;
  width: 30px;
  text-align: center;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }
`

const styleButton = css`
  margin: 2px;

  &::after {
    font-family: 'bonifont';
    color: ${colors.white};
  }
`

const minusButton = css`
  &::after {
    content: '\\${iconHex('minus')}';
  }
`

const plusButton = css`
  &::after {
    content: '\\${iconHex('plus')}';
  }
`

export function Counter ({
  className,
  onChange,
  isDisabledSubtractButton = false,
  isDisabledAddButton = false,
  isReadOnlyInput = true,
  ...other
}) {
  const handleChange = useCallback((newValue) => {
    if (onChange) {
      onChange(newValue)
    }
  }, [onChange])

  const onSubtractButtonClick = useCallback(() => {
    handleChange(Math.max(other.value - 1, other.min))
  }, [handleChange, other.min, other.value])

  const onAddButtonClick = useCallback(() => {
    handleChange(Math.min(other.value + 1, other.max))
  }, [handleChange, other.max, other.value])

  const onCounterInputChange = useCallback(({ target: { value } }) => {
    if (/^\d*$/.test(value) && onChange) {
      if (value === '') {
        handleChange('')
        return
      }

      const parsedValue = Number(value)

      handleChange(parsedValue)
    }
  }, [handleChange, onChange])

  const onInputBlur = useCallback(() => {
    if (other.value === '') {
      handleChange(other.min)
    }
  }, [handleChange, other.min, other.value])

  return (
    <div css={[flexHorizontallyCentered, baseFont]}>
      <CircleButton
        css={[styleButton, minusButton]}
        onClick={onSubtractButtonClick}
        disabled={isDisabledSubtractButton}
      />

      <input
        data-testid='counter'
        type='text'
        css={inputStyle(isReadOnlyInput)}
        className={className}
        onChange={onCounterInputChange}
        readOnly={isReadOnlyInput}
        onBlur={onInputBlur}
        {...other}
      />

      <CircleButton
        css={[styleButton, plusButton]}
        onClick={onAddButtonClick}
        disabled={isDisabledAddButton}
      />
    </div>
  )
}

export const CounterHiddenValue = props => (
  <Counter
    {...props}
    css={css`
      display: none;
    `}
  />
)
