import { ErrorMap } from '@/types/domains/Error'

export const ReservationCreationErrorMapping: ErrorMap = {
  'contracts::validation_contracts::invalid': ({ extra_data: { slot = '' } = {} }) => slot ? 'Horário excedido. Selecione um horário futuro' : '',
  'atomizer::vacancy::unavailable': ({ extra_data: { available = 0 } = {} }) => available > 0 ? `Limite diário de vagas excedido. Resta(m) apenas ${available} vaga(s)` : 'Não resta nenhuma vaga',
  'uc_create_limber_ticket::limber_invalid_country': () => 'País informado para o responsável é inválido para esta experiência.'
}

export const ReservationAddTicketsErrorMapping: ErrorMap = {
  'ticket_seller::reservation::not_found': () => 'Não é possível vincular a reserva de outra empresa',
  'atomizer::vacancy::unavailable': ({ extra_data: { available = 0 } = {} }) => available > 0 ? `Limite de vagas excedido. Resta(m) apenas ${available} vaga(s)` : 'Não resta nenhuma vaga'
}
