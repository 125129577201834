import { css } from '@emotion/core'
import { colors } from '../../assets/styles/colors'
import { resetNativeInput } from '../../assets/styles/input'

export const selectContainer = css`
  background: ${colors.white};
  color: ${colors.gray1};
  display: inline-block;
  white-space: nowrap;
  box-sizing: border-box;
  min-height: 40px;
  min-width: 50px;
  border-radius: var(--round-element-radius, 20px);
  border: 1px solid ${colors.gray8};
  padding: 10px 20px;
  padding-right: 45px;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  user-select: none;
  outline: none;
`

export const drawerPosition = css`
  right: -15px;
  top: 5px;
  z-index: 4;
`

export const drawerPositionVariant = css`
 .component-drawer-container {
    right: -45px;
    bottom: 30px;
    z-index: 2;
    top: unset;

    &::after {
      bottom: calc(0% - 9px);
      top: unset;
      transform: rotate(180deg);
    }    
 }
 
`

export const selectArrowIcon = css`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 20px;
  transition: 0.2s transform ease;
  color: ${colors.gray2};
`

export const arrowOpenIndicator = css`
  transform: rotateZ(-180deg);
`

export const selectDisabled = css`
  color: ${colors.gray6};
  background-color: ${colors.gray11};
  pointer-events: none;
`

export const selectError = css`
  border-color: ${colors.danger};
`

export const hidden = css`
  display: none;
`

export const selectInputText = css`
  ${resetNativeInput};
  font-size: 15px;
  color: ${colors.gray3};
  background-color: transparent;
  border: 0;
  padding: 0;
  outline: none;
  max-width: 100%;
  width: 100%;
`
